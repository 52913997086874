const ADD_VIDEOS = 'ADD_VIDEOS';
const UPDATE_KEYWORD = 'UPDATE_KEYWORD';
const SELECT_VIDEO = 'SELECT_VIDEO';
const CALL_ERROR = 'CALL_ERROR';

export {
  ADD_VIDEOS,
  UPDATE_KEYWORD,
  SELECT_VIDEO,
  CALL_ERROR
}